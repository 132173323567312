import {Center} from '~/components/Center';
import {TitleSection} from '~/components/TitleSection';
import {Cards} from './Cards';
import {Title} from './Title';
import {AplicacionType} from '~/services/GetPageHomeService';

export function Applications({
  ...props
}: {
  aplicaciones: AplicacionType[];
  logoUrl: string;
}) {
  return (
    <Center
      as="section"
      className="flex flex-col items-center py-12 px-0 sm:px-10 xl:px-20"
    >
      <TitleSection
        className="mb-8"
        i18nKey="pages:inicio:applications.title.0"
      />

      <Title className="mb-16" />

      {/*
      <div className="flex flex-col gap-8 items-center my-10 xl:flex-row xl:justify-between">
        <Title />
        <Action />
      </div> */}

      <Cards {...props} />
    </Center>
  );
}
