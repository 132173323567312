import {Center} from '~/components/Center';
import {SectionQuoteBase} from '~/components/SectionQuoteBase';
import {Trans} from '~/components/Trans';
import {ImageType} from '~/features/shared';

export function SectionQuote({image}: {image?: ImageType}) {
  return (
    <Center className="px-5 pt-20 sm:px-10 xl:px-20">
      <SectionQuoteBase
        img={image?.image}
        title={<Trans i18nKey="pages:inicio:quote.title" />}
        callToActionText={<Trans i18nKey="pages:inicio:quote.action.title" />}
      />
    </Center>
  );
}
