import {Image} from '@shopify/hydrogen';
import {Link} from '~/components/Link';
import {About} from './About';
import {Applications} from './Applications';
import {Clients} from './Clients';
import {SectionQuote} from './SectionQuote';
import {HomePageType} from '~/services/GetPageHomeService';
import {Hero} from '~/components/Hero';
import {Trans} from '~/components/Trans';

export function HomePage({page}: {page: HomePageType}) {
  return (
    <>
      <Hero
        video={page.video}
        title={<Trans i18nKey="pages:inicio:hero.title" />}
      />
      <Applications
        aplicaciones={page.aplicaciones}
        logoUrl="https://cdn.shopify.com/s/files/1/0745/9438/6205/files/hpm-led-logo_190x36.png?v=1682837963"
      />
      <Clients clientes={page.clientes} />
      <About image={page.imagen1} />
      <SectionQuote image={page.cotizarImage} />

      {page.urlWhatsApp ? (
        <Link
          to={page.urlWhatsApp}
          target="_blank"
          className="fixed right-5 bottom-5 z-40 md:right-10 md:bottom-10"
        >
          <Image
            className="w-[50px] h-[52px] md:w-[83px] md:h-[95px]"
            width={83}
            height={95}
            src="https://cdn.shopify.com/s/files/1/0745/9438/6205/files/whatsapp-logo.png?v=1685732696"
          />
        </Link>
      ) : null}
    </>
  );
}
