import {Center} from '~/components/Center';
import {Paragraph} from '~/components/Paragraph';
import {Title} from '~/components/Title';
import {TitleSection} from '~/components/TitleSection';
import {Trans} from '~/components/Trans';
import {ImageType} from '~/features/shared';

export function About({image}: {image?: ImageType}) {
  return (
    <section className="bg-gray-100">
      <Center className="flex gap-10 items-center py-20 px-10 xl:gap-12 xl:px-20">
        <img
          alt={image?.image.altText}
          src={image?.image.url}
          width={390}
          height={560}
          className="hidden object-cover bg-black rounded-lg lg:block"
          style={{
            width: 390,
            height: 560,
          }}
        />

        <article className="flex-1">
          <TitleSection i18nKey="pages:inicio:about.title.0" className="mb-3" />

          <Title
            i18nKey="pages:inicio:about.title.1"
            className="mb-8 text-3xl leading-tight lg:leading-normal"
          />

          <ul className="flex flex-col gap-8">
            <Item
              title="pages:inicio:about.content.0.title"
              description="pages:inicio:about.content.0.description"
              icon="https://cdn.shopify.com/s/files/1/0745/9438/6205/files/inicio-about-bubble-0.svg?v=1684956791"
            />
            <Item
              title="pages:inicio:about.content.1.title"
              description="pages:inicio:about.content.1.description"
              icon="https://cdn.shopify.com/s/files/1/0745/9438/6205/files/inicio-about-bubble-2.svg?v=1684956800"
            />
            <Item
              title="pages:inicio:about.content.2.title"
              description="pages:inicio:about.content.2.description"
              icon="https://cdn.shopify.com/s/files/1/0745/9438/6205/files/inicio-about-bubble-1.svg"
            />
          </ul>
        </article>
      </Center>
    </section>
  );
}

interface ItemProps {
  title: string;
  description: string;
  icon?: string;
}

function Item({title, description, icon}: ItemProps) {
  return (
    <li className="flex flex-col gap-6 sm:flex-row">
      {icon ? (
        <img className="hidden sm:block" alt="Icono" src={icon} width={90} />
      ) : null}

      <div className="flex-1">
        <h2 className="mb-3 text-lg font-bold text-blue-dark">
          <Trans i18nKey={title} />
        </h2>

        <Paragraph i18nKey={description} className="text-sm text-black" />
      </div>
    </li>
  );
}
