import {twMerge} from 'tailwind-merge';
import {Trans} from '~/components/Trans';

interface TitleProps {
  className?: string;
}

export function Title({className}: TitleProps) {
  return (
    <div
      className={twMerge(
        'flex gap-8 flex-col items-center sm:flex-row',
        className
      )}
    >
      <div
        className="bg-blue-dark flex justify-center items-center w-32 h-32 rounded-full"
        style={{
          background:
            'linear-gradient(135.36deg, #424CD5 9.71%, #4452D3 9.72%, #25A4F8 100%)',
        }}
      >
        <h1 className="text-white font-bold text-6xl">
          <Trans i18nKey="pages:inicio:applications.title.1" />
        </h1>
      </div>

      <h1 className="uppercase font-light flex-1 text-blue-dark text-4xl lg:text-5xl text-center sm:text-left">
        <Trans i18nKey="pages:inicio:applications.title.2" />
      </h1>
    </div>
  );
}
