import {useSpring, animated} from '@react-spring/web';
import {Link} from '@remix-run/react';
import {useEffect, useRef, useState} from 'react';
import {Trans} from 'react-i18next';
import {twMerge} from 'tailwind-merge';
import {AplicacionType} from '~/services/GetPageHomeService';
import {ImageType, MediaType, useLongPress, VideoType} from '~/features/shared';

export function Card({
  aplicacion,
  logoUrl,
}: {
  aplicacion: AplicacionType;
  logoUrl: string;
}) {
  const to = aplicacion.advertisingPage
    ? `/${aplicacion.advertisingPage}`
    : `/proyectos/${aplicacion.name}`;

  const [s, api] = useSpring(() => ({
    opacity: 1,
  }));

  const [isPlaying, setIsPlaying] = useState(false);

  const onStart = () => {
    api.start({
      opacity: 0,
    });

    setIsPlaying(true);
  };

  const onEnd = () => {
    api.start({
      opacity: 1,
    });
    setIsPlaying(false);
  };

  const {onTouchStart, onTouchEnd} = useLongPress(onStart, 500);

  return (
    <div
      onMouseEnter={onStart}
      onMouseLeave={onEnd}
      onTouchStart={onTouchStart}
      onTouchEnd={() => {
        onTouchEnd();
        onEnd();
      }}
      className="flex overflow-hidden relative justify-center items-center bg-black select-none sm:rounded-md h-[18rem]"
    >
      {aplicacion.video ? (
        <ApplicationMedia media={aplicacion.video} isPlaying={isPlaying} />
      ) : null}

      <animated.div
        className="absolute inset-0"
        style={{
          opacity: s.opacity,
        }}
      >
        {aplicacion.image ? (
          <img
            className="object-cover w-full h-full"
            src={aplicacion.image.image.url}
            alt={aplicacion.image.image.altText}
            width={600}
            height={350}
          />
        ) : null}
        <img
          src={logoUrl}
          alt="HPMLED Logo"
          className="absolute"
          style={{
            width: 100,
            height: 16,
            right: 10,
            bottom: 10,
          }}
        />
      </animated.div>

      <div className="absolute inset-0 bg-black opacity-30" />

      <h1
        className={twMerge(
          'z-0 px-4 text-3xl font-bold text-center text-white uppercase lg:text-4x',
          aplicacion.hasProjects && 'hover:underline decoration-white'
        )}
      >
        {aplicacion.hasProjects ? (
          <Link to={to}>
            <Trans i18nKey={aplicacion.name} />
          </Link>
        ) : (
          <Trans i18nKey={aplicacion.name} />
        )}
      </h1>
    </div>
  );
}

function ApplicationMedia({
  media,
  isPlaying,
}: {
  media: MediaType;
  isPlaying: boolean;
}) {
  if (media.mediaContentType === 'IMAGE') {
    return <ApplicationImage isPlaying={isPlaying} image={media} />;
  }

  if (media.mediaContentType === 'VIDEO') {
    return <ApplicationVideo isPlaying={isPlaying} video={media} />;
  }

  return null;
}

function ApplicationImage({image}: {image: ImageType; isPlaying: boolean}) {
  return (
    <img
      className="object-cover absolute inset-0 h-[18rem]"
      src={image.image.url}
      alt={image.image.altText}
      width={600}
      height={350}
    />
  );
}

function ApplicationVideo({
  video,
  isPlaying,
}: {
  video: VideoType;
  isPlaying: boolean;
}) {
  const mp4 = video.sources.filter((s) => s.mimeType === 'video/mp4');
  const s = mp4.length >= 2 ? mp4[1] : mp4[0];

  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isPlaying) {
      ref.current?.play();
    } else {
      ref.current?.pause();
    }
  }, [isPlaying]);

  return (
    <video
      onContextMenu={(e) => e.preventDefault()}
      controlsList="nodownload"
      ref={ref}
      muted
      loop
      playsInline
      className="object-cover absolute inset-0 w-full h-[18rem]"
      poster={video.previewImage.url}
      height="18rem"
    >
      <source src={s.url} type={s.mimeType} />
    </video>
  );
}
