import {useLoaderData} from '@remix-run/react';
import {LoaderArgs} from '@shopify/remix-oxygen';
import {HomePage} from '~/pages/HomePage';

export {meta} from '~/utils/meta';

export async function loader({context, request}: LoaderArgs) {
  const data = await context.getPageHomeService.get();

  if (!data) {
    throw new Response('Not found', {status: 404});
  }

  return data;
}

export default function Index() {
  const page = useLoaderData<typeof loader>();

  return <HomePage page={page} />;
}
