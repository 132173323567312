import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '~/components/Button';
import {useWindowSize} from '~/features/shared';
import {AplicacionType} from '~/services/GetPageHomeService';
import {Card} from './Card';

export function Cards({
  aplicaciones,
  logoUrl,
}: {
  aplicaciones: AplicacionType[];
  logoUrl: string;
}) {
  const {t} = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const length = aplicaciones.length;

  const firstHalf = aplicaciones.slice(0, 6);
  const secondHalf = aplicaciones.slice(6);

  const {width} = useWindowSize();

  let max = length / 2 - 3;

  if (width && width < 768) {
    max = length - 6;
  }

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = isOpen
        ? `calc(18rem * ${max} + 1rem * ${max - 1})`
        : '0px';
    }
  }, [isOpen, max]);

  return (
    <>
      <div
        className="grid grid-cols-1 gap-4 w-full md:grid-cols-2"
        style={{
          marginBottom: isOpen ? '1rem' : 0,
        }}
      >
        {firstHalf.map((aplicacion, i) => {
          return <Card aplicacion={aplicacion} logoUrl={logoUrl} key={i} />;
        })}
      </div>

      <div
        ref={ref}
        className="w-full"
        style={{
          overflow: 'hidden',
          transition: 'height 0.5s',
          height: 0,
        }}
      >
        <div className="grid grid-cols-1 gap-4 w-full md:grid-cols-2">
          {secondHalf.map((aplicacion, i) => {
            return <Card aplicacion={aplicacion} logoUrl={logoUrl} key={i} />;
          })}
        </div>
      </div>

      <Button
        onClick={() => {
          // api.start({t: isOpen ? 0 : 1});
          setIsOpen((p) => !p);
        }}
        className="px-10 mt-16"
      >
        {isOpen ? t('ver-menos') : t('ver-mas')}
      </Button>
    </>
  );
}
